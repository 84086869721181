<template>
  <div class="modal">
    <div class="modal-wrapper">
      <div class="header"><h2>Delete User</h2></div>

      <div class="modal-content">
        <h2>{{ email }}</h2>
        <p>
          will be removed from sStream permanently. This action cannot be
          undone.
        </p>
      </div>
      <div class="modal-footer">
        <button class="button" @click="closeModal">Cancel</button>
        <button id="deleteUser" class="button" @click="deleteUser">
          Delete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";

export default {
  props: ["userId"],
  emits: ["close-modal"],
  data() {
    return {
      email: "",
    };
  },
  created() {
    const docRef = db.collection("users").doc(this.userId);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.email = doc.data().email;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  },
  methods: {
    async deleteUser() {
      const userDatabase = db.collection("users");
      userDatabase.doc(this.userId).collection("roomAccess").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          db.collection("streamRooms").doc(doc.data().uid).collection("permittedUsers").doc(this.userId).delete().then(() => {
              console.log("Document successfully deleted!");
            }).catch((error) => {
              console.error("Error removing document: ", error);
            });
        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });
      await userDatabase
        .doc(this.userId)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          const addMessage = firebase.functions().httpsCallable("deleteUser");
          var data = {
            userId: this.userId
            }
          addMessage(data)
          .then((result) => {
            console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });

          this.closeModal();
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);

  .modal-wrapper {
    display: flex;
    flex-direction: column;
    width: 580px;
    background-color: #383c49;

    .header {
      background-color: #383c49;
      height: 64px;
      display: flex;
      align-items: center;
      color: #e5ecff;
      font-size: 1.5em;
      font-weight: 700;
      padding-left: 25px;
      border-bottom: 1px solid RGB(38, 40, 48);

      p {
        text-align: center;
      }

      button {
        align-self: center;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
      text-align: center;
      height: 100%;
      align-items: center;
      background-color: RGB(68, 75, 90);
      border: solid #383c49;
      border-width: 20px 20px 20px 20px;

      h2 {
        margin: 0px;
      }

      p {
        width: 350px;
      }
    }
    .modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: auto;
      background-color: RGB(47, 50, 60);
      width: 100%;
      height: 64px;
      flex-shrink: 0;
      border-top: 1px solid RGB(38, 40, 48);

      .button {
        position: relative;
        padding: 0px 15px;
        max-height: 37px;
        min-height: 33px;
        font-size: 12px;
        font-weight: 700;
        background-color: RGBA(68, 75, 90, 0.2);
        border: 1px solid #444b5a;
        color: inherit;
        cursor: pointer;
        min-width: 33px;
        margin-left: 10px;
        text-transform: uppercase;
      }

      #deleteUser {
        background-color: RGBA(233, 60, 45, 0.2);
        border: 1px solid RGB(233, 60, 45);
        margin-right: 20px;
      }

      #deleteUser:hover {
        background-color: RGB(233, 60, 45, 0.8);
      }

      .button:hover {
        background-color: #505874;
      }
    }
  }
}
</style>