<template>
  <loading v-if="processing" />
  <div class="modal">
    <div class="header"><h2>Edit User</h2></div>
    <div class="modal-content">
      <div class="form-wrap background">
        <form class="createUser">
          <div class="form-header">
            <h5>USER INFORMATION</h5>
          </div>
          <div class="inputs">
            <div class="input">
              <label
                >First Name<span
                  class="error"
                  v-for="error of v$.firstName.$errors"
                  :key="error.$uid"
                  >*</span
                ></label
              >
              <input
                type="text"
                placeholder="First Name"
                v-model="v$.firstName.$model"
              />
            </div>
            <div class="input">
              <label
                >Last Name<span
                  class="error"
                  v-for="error of v$.lastName.$errors"
                  :key="error.$uid"
                  >*</span
                ></label
              >
              <input
                type="text"
                placeholder="Last Name"
                v-model="v$.lastName.$model"
              />
            </div>
            <div class="input">
              <label
                >Email<span
                  class="error"
                  v-for="error of v$.email.$errors"
                  :key="error.$uid"
                  >*</span
                ></label
              >
              <input
                type="text"
                placeholder="Email"
                v-model="v$.email.$model"
              />
            </div>

            <div class="input">
              <label>Phone Number </label>

              <vue-tel-input
                v-model="phoneNumber"
                v-bind="options"
                @on-input="onInput"
              />
            </div>

            <div class="input">
              <label>Company</label>
              <input
                type="text"
                placeholder="Production Company (optional)"
                v-model="prodCompany"
              />
            </div>
            <div class="form-header">
              <h5>SYSTEM</h5>
            </div>

            <div class="input">
              <label>Role</label>
              <select placeholder="Role" v-model="userRole">
                <option value="admin">Admin</option>
                <option value="broadcaster">Broadcaster</option>
                <option value="viewer" selected="selected">Viewer</option>
              </select>
            </div>

            <div class="input">
              <label>Disable User</label>
              <select placeholder="User Status" v-model="disableUser">
                <option value="false" selected="selected">
                  Enabled
                </option>
                <option value="true">Disabled</option>
              </select>
            </div>

            <div class="form-header">
              <h5>ASSIGN PROJECTS</h5>
            </div>

            <div v-show="error" class="error">{{ this.errorMsg }}</div>
          </div>
          <button @click.prevent="updateUser">Update User</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import { VueTelInput } from "vue-tel-input";
// import "../vue-tel-input/component.css";
import "../vue-tel-input/sprite.css";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../firebase/firebaseInit.js";
import loading from "../Loading.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, alpha } from "@vuelidate/validators";

export default {
  name: "Create User Modal",
  props: ["userId"],
  emits: ["close-modal"],
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: null,
      phoneFormatted: null,
      phoneErrorVisibility: false,
      phoneErrorState: null,
      password: "",
      prodCompany: "",
      userRole: "Viewer",
      uid: "",
      error: "",
      errorMsg: "",
      processing: false,
      options: {
        mode: "international",
        autoFormat: true,
        defaultCountry: "US",
        placeholder: "Enter a phone number",
        required: true,
        enabledCountryCode: true,
        autocomplete: true,
        name: "telephone",
        maxLen: 25,
        phone: {},
        inputOptions: {
          styleClasses: "",
        },
        dropdownOptions: {
          showDialCodeInList: true,
          showFlags: true,
        },
      },
    };
  },
  components: {
    loading,
    VueTelInput,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      userRole: required,
    };
  },
  created() {
    const docRef = db.collection("users").doc(this.userId);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data().firstName);
          this.firstName = doc.data().firstName;
          this.lastName = doc.data().lastName;
          this.emailVerified = doc.data().emailVerified;
          this.email = doc.data().email;
          this.phoneNumber = doc.data().phoneNumber;
          this.userRole = doc.data().userRole;
          this.prodCompany = doc.data().prodCompany;

          let disabledUserBuffer = doc.data().disableUser;
          
          console.log(doc.data().disableUser)
          console.log(disabledUserBuffer)
          if (disabledUserBuffer == true) {
            this.disableUser = true
          } else if (disabledUserBuffer == false) {
            this.disableUser = false
          }
          this.uid = doc.data().uid;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  },
  mounted() {},
  methods: {
    closeModal() {
      this.processing = false;
      this.$emit("close-modal");
    },
    onInput(formattedNumber, phoneObject) {
      this.phoneFormatted = phoneObject.number;
      console.log(this.phoneFormatted);
    },

    async updateUser() {
      const addMessage = firebase.functions().httpsCallable("AdminEditUser");
      var data = {
        uid: this.uid,
        email: this.email,
        emailVerified: this.emailVerified,
        phoneNumber: this.phoneFormatted,
        admin: this.userRole == "Admin",
        userRole: this.userRole,
        firstName: this.firstName,
        lastName: this.lastName,
        disableUser: this.disableUser,
        prodCompany: this.prodCompany
      };
      if (this.phoneFormatted == null) {
        data.phoneNumber = this.phoneNumber
      }
      if (
        this.email !== "" &&
        this.firstName !== "" &&
        this.lastName !== "" &&
        this.userRole !== "" &&
        this.phoneFormatted !== ""
      ) {
        console.log(data.disableUser)
        this.processing = true;
        this.error = false;
        this.errorMsg = "";
        addMessage(data)
          .then((result) => {
            console.log(result);
             this.closeModal();
          })
          .catch((error) => {
            console.log(error);
            this.closeModal();
          });
        return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields!";
      return;
    },

    // async register() {
    //   const addMessage = firebase.functions().httpsCallable("CreateUser");

    //   var data = {
    //     email: this.email,
    //     password: this.password,
    //     phoneNumber: this.phoneNumber,
    //     admin: this.userRole == "Admin",
    //     userRole: this.userRole,
    //     firstName: this.firstName,
    //     lastName: this.lastName,
    //   };

    //   if (
    //     this.email !== "" &&
    //     this.password !== "" &&
    //     this.firstName !== "" &&
    //     this.lastName !== "" &&
    //     this.userRole !== ""
    //   ) {
    //     this.error = false;
    //     this.processing = true;
    //     this.errorMsg = "";
    //     addMessage(data)
    //       .then((result) => {
    //         console.log(result);
    //         this.closeModal();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.closeModal();
    //       });

    //     return;
    //   }
    //   this.error = true;
    //   this.errorMsg = "Please fill out all the fields!";
    //   return;
    // },
  },
};
</script>
<style lang="scss">
.vti__input {
  height: 28px !important;
  margin: 0px !important;
}

.vti__dropdown {
  margin: 0px !important;
  height: 26px !important;
  width: 50px !important;
  padding: 0px !important;
  margin-right: 5px !important;
}

.vue-tel-input {
  display: flex !important;
  text-align: left !important;
  width: 207px !important;
  margin-bottom: 5px !important;
}

.vti__dropdown-list {
  z-index: 1002 !important;
}
</style>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  flex-shrink: 2;
  flex-grow: 3;
  max-width: 360px;
  background-color: #2f323c;
  height: 600px;
  margin-left: 10px;

  .header {
    background-color: #383c49;
    height: 70px;
    display: flex;
    align-items: center;
    color: #e5ecff;
    font-size: 1.5em;
    font-weight: 700;
    padding-left: 25px;

    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
}

.modal-content {
display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    padding: 15px 15px;
    height: 100%;
    background-color: #2f323c;

  .createUser {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  label {
    font-size: 12px;
    color: RGB(239, 243, 255);
    flex-shrink: 2;
  }

  input {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 0px 0px 5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  select {
    height: 28px;
    background-color: #262830;
    border: 1px solid #17181a;
    margin: 0px 0px 5px 0px;
    color: #eff3ff;
    padding-left: 10px;
    width: 207px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .checkbox {
      height: auto;
      margin: 0px 10px 0px 39px;
      margin-right: auto;
    }
  }

  button {
    background-color: RGB(0, 132, 255);
    border: none;
    height: 37px;
    width: 100%;
    color: #eff3ff;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
    margin-top: auto;
  }

  button:hover {
    background-color: #0084ff;
  }
}

.form-header {
  border-bottom: 1px solid RGB(123, 138, 173);
  margin-bottom: 15px;
  display: flex !important;
}

.form-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.emails.emails-input {
  max-height: inherit;
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #c3c2cf;
  box-sizing: border-box;
  padding: 0.375rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  cursor: text;
  overflow: auto;
}

.emails.emails-input .email-chip {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  background: rgba(102, 153, 255, 0.2);
  vertical-align: top;
  border-radius: 6.25rem;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  margin: 0.125rem;
  max-width: 100%;
  overflow: hidden;
}

.emails.emails-input .email-chip .content {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emails.emails-input .email-chip .remove {
  text-decoration: none;
  color: inherit;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 1rem;
  font-size: 1rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.emails.emails-input .email-chip.invalid {
  background: #fff;
  border-bottom: 1px dashed #d92929;
  border-radius: 0;
  padding-left: 0;
  padding-right: 1rem;
}

.emails.emails-input input {
  border: 0;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  margin: 0.125rem;
}

.emails.emails-input input::placeholder,
.emails.emails-input input::-ms-input-placeholder,
.emails.emails-input input:-ms-input-placeholder {
  color: #c3c2cf;
  opacity: 1;
}

.emails.emails-input input:focus {
  outline: none;
}

.error {
  color: #ad0000 !important;
  font-size: 14px;
}
</style>