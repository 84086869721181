<template>
<loading v-if="processing" />
  <div class="modal">
    <div class="modal-content">
      <div class="form-wrap background">
        <vue-tags-input
          v-model="tag"
          :tags="tags"
          :add-on-key="[13, ':', ';', ' ', ',']"
          :allow-edit-tags="true"
          :separators="[';', ',', ' ']"
          :validation="emailvalidator"
          @tags-changed="(newTags) => (tags = newTags)"
        />

        <button @click.prevent="register">Send Invites</button>
        <button @click.prevent="closeModal">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import VueTagsInput from "@sipec/vue3-tags-input";
import loading from "../Loading.vue"

export default {
  name: "Invite Create User Modal",
  components: {
    VueTagsInput,
    loading
  },
  data() {
    return {
      processing: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: null,
      password: "",
      userRole: "",
      uid: "",
      error: "",
      errorMsg: "",
      tag: "",
      tags: [],
      emailvalidator: [
        {
          classes: "email",
          rule: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
          disableAdd: true,
        },
      ],
    };
  },
  created() {},
  methods: {
    closeModal() {
      this.processing = false
      this.$emit("close-modal");
    },

    async register() {
      const addMessage = firebase.functions().httpsCallable("inviteUser");

      var data = {
        source: this.$route.path,
        tags: this.tags,
      };

      console.log(data);

      data.tags.forEach(function (value) {
        console.log(value)
      });
      this.processing = true;
      addMessage(data)
        .then((result) => {
          console.log(result);
          this.closeModal();
        })
        .catch((error) => {
          console.log(error);
          this.closeModal();
        });

      /*if (
        this.email !== "" &&
        this.password !== "" &&
        this.firstName !== "" &&
        this.lastName !== "" &&
        this.userRole !== ""
      ) {
        this.error = false;
        this.errorMsg = "";
        addMessage(data)
          .then(function (result) {
            console.log(result);
          })
          .catch(function (error) {
            console.log(error);
          });
          this.closeModal();
        return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields!";
      return; */
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    width: 300px;
    padding: 40px 30px;
    background-color: black;

    p {
      text-align: center;
    }

    button {
      align-self: center;
    }
  }
}

.emails.emails-input {
  max-height: inherit;
  border-radius: 0.25rem;
  background: #fff;
  border: 1px solid #c3c2cf;
  box-sizing: border-box;
  padding: 0.375rem;
  line-height: 1.5rem;
  font-size: 0.875rem;
  cursor: text;
  overflow: auto;
}

.emails.emails-input .email-chip {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  background: rgba(102, 153, 255, 0.2);
  vertical-align: top;
  border-radius: 6.25rem;
  padding-left: 0.625rem;
  padding-right: 1.5rem;
  margin: 0.125rem;
  max-width: 100%;
  overflow: hidden;
}

.emails.emails-input .email-chip .content {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emails.emails-input .email-chip .remove {
  text-decoration: none;
  color: inherit;
  text-align: center;
  position: absolute;
  cursor: pointer;
  width: 1rem;
  font-size: 1rem;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.emails.emails-input .email-chip.invalid {
  background: #fff;
  border-bottom: 1px dashed #d92929;
  border-radius: 0;
  padding-left: 0;
  padding-right: 1rem;
}

.emails.emails-input input {
  border: 0;
  line-height: inherit;
  font-size: inherit;
  color: inherit;
  margin: 0.125rem;
}

.emails.emails-input input::placeholder,
.emails.emails-input input::-ms-input-placeholder,
.emails.emails-input input:-ms-input-placeholder {
  color: #c3c2cf;
  opacity: 1;
}

.emails.emails-input input:focus {
  outline: none;
}
</style>